<template>
  <a-modal :visible="visible" :footer="null" maskClosable @cancel="close">
    <span slot="title">
      <a-icon type="question-circle" style="margin-right: 6px" />
      Quick Help Menu
    </span>

    <h3>Keyboard Shortcuts</h3>
    <ul>
      <li>Press <code>?</code> to open the help menu.</li>
      <li>Press <code>/</code> to focus on the search bar.</li>
      <li>
        Press <code>ctrl</code> + <code>shift</code> + <code>1</code> to go to
        your dashboard.
      </li>
      <li>
        Press <code>ctrl</code> + <code>shift</code> + <code>2</code> to go to
        the {{ $t("components") }} page.
      </li>
    </ul>
    <a-divider style="margins: 12px 0px" />
    <p>
      For more help, reach out to us at
      <a href="mailto:support@opslevel.com">support@opslevel.com</a> or take a
      look through our
      <a href="https://docs.opslevel.com/docs/" target="_blank">documentation</a
      >.
    </p>
  </a-modal>
</template>

<script>
export default {
  data() {
    return {
      visible: false,
    };
  },

  methods: {
    show() {
      this.visible = true;
    },
    close() {
      this.visible = false;
    },
  },
};
</script>

<style scoped>
code {
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  background: #f9f5f1;
  padding: 2px;
}

li {
  margin-top: 4px;
}
</style>
