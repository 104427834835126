import * as types from "./mutation_types.js";
import axios from "@/lib/axios.js";
import extractErrors from "@/lib/extract_errors.js";
import extractApolloErrors from "@/lib/extract_apollo_errors.js";
import { gqlClient } from "@/shared/apolloClient.js";
import { get } from "lodash";
import {
  INTEGRATION_NAME_MUTATION,
  INTEGRATION_DEAUTH_MUTATION,
  INTEGRATION_SYNC_ALERT_SOURCES_MUTATION,
  INTEGRATION_SYNC_MUTATION,
  SET_INTEGRATION_GROUP,
  UPDATE_INTEGRATION_CREDENTIALS,
  UPDATE_DATADOG_CREDENTIALS,
  UPDATE_DD_NOTIFICATION_CHANNEL,
  UPDATE_DD_SET_WEBHOOKS_ON_MONITORS,
  UPDATE_INTEGRATION_BASE_URL,
  FETCH_GIT_FORGE_INTEGRATIONS,
  QUERY_INTEGRATIONS,
  QUERY_INTEGRATIONS_INDEX,
  FETCH_AVAILABLE_INTEGRATIONS,
  FETCH_AVAILABLE_INTEGRATIONS_INITIAL_LOAD,
  DELETE_INTEGRATION_MUTATION,
  DELETE_GITFORGE_INTEGRATION_MUTATION,
  VALIDATE_INTEGRATION_CREDENTIALS,
  UPDATE_INTEGRATION_SERVICE_DISCOVERY_ENABLED,
  UPDATE_INTEGRATION_SERVICE_DISCOVERY_IGNORE_RULES,
  UPDATE_INTEGRATION_ALLOWED_REPO_VISIBILITY,
  FETCH_GENERIC_INTEGRATIONS,
  FETCH_INTEGRATION_DETAILS,
  SUBSCRIBE_INTEGRATION_DETAILS,
  FETCH_INFRA_INTEGRATION_DETAILS,
  CREATE_NEW_RELIC_INTEGRATION,
  UPDATE_NEW_RELIC_INTEGRATION,
  FETCH_NEW_RELIC_ACCOUNTS,
  CREATE_SAMPLE_GIT_INTEGRATION,
  UPDATE_INTEGRATION_TEAM_SYNC,
  UPDATE_INTEGRATION_WARNING,
  UPDATE_INTEGRATION_SBOM_GENERATION_ENABLED,
  CREATE_AWS_INTEGRATION,
  CREATE_AZURE_RESOURCES_INTEGRATION,
  CREATE_GOOGLE_CLOUD_INTEGRATION,
  CREATE_OCTOPUS_DEPLOY_INTEGRATION,
  CREATE_SNYK_INTEGRATION,
  CREATE_SONARQUBE_INTEGRATION,
  CREATE_SONARQUBE_CLOUD_INTEGRATION,
  UPDATE_OCTOPUS_DEPLOY_INTEGRATION,
  CREATE_INCIDENT_IO_INTEGRATION,
  UPDATE_INCIDENT_IO_INTEGRATION,
  FETCH_INTREGRATION_SHOW_DETAILS,
} from "./queries.js";

import { acquireLock, validLock } from "@/shared/lock_helper.js";

export const setInitialData = ({ commit }, data) => {
  commit(types.SET_INITIAL_DATA, data);
};

export const resetCredentialsState = ({ commit }) => {
  commit(types.RESET_CREDENTIAL_STATE);
};

export const fetchGitForgeIntegrations = ({ commit }) => {
  commit(types.REQUEST_FETCH_GIT_FORGE_INTEGRATIONS);

  gqlClient
    .query({
      query: FETCH_GIT_FORGE_INTEGRATIONS,
      variables: null,
    })
    .then((response) => {
      const bitbucket = get(response, "data.account.bitbucket.nodes", []);
      const gitlab = get(response, "data.account.gitlab.nodes", []);
      const gitlabSelfManaged = get(
        response,
        "data.account.gitlabSelfManaged.nodes",
        [],
      );
      const github = get(response, "data.account.github.nodes", []);
      const azureDevops = get(response, "data.account.azureDevops.nodes", []);

      commit(types.RECEIVE_FETCH_GIT_FORGE_INTEGRATIONS_SUCCESS, [
        ...bitbucket,
        ...github,
        ...gitlab,
        ...gitlabSelfManaged,
        ...azureDevops,
      ]);
    })
    .catch((errors) => {
      commit(types.RECEIVE_FETCH_GIT_FORGE_INTEGRATIONS_ERRORS, errors);
    });
};

export const fetchAvailableIntegrations = (
  { commit },
  { filters, initialLoad = false } = {},
) => {
  commit(types.REQUEST_FETCH_AVAILABLE_INTEGRATIONS);

  const query = initialLoad
    ? FETCH_AVAILABLE_INTEGRATIONS_INITIAL_LOAD
    : FETCH_AVAILABLE_INTEGRATIONS;

  gqlClient
    .query({
      query,
      variables: filters,
    })
    .then((response) => {
      const payload = {
        availableIntegrations: get(
          response,
          "data.account.availableIntegrations",
          [],
        ),
        integrationCategories: get(
          response,
          "data.integrationCategories.enumValues",
          [],
        ),
      };

      commit(types.RECEIVE_FETCH_AVAILABLE_INTEGRATIONS_SUCCESS, payload);
    })
    .catch((errors) => {
      commit(types.RECEIVE_FETCH_AVAILABLE_INTEGRATIONS_ERRORS, errors);
    });
};

export const requestCreateDeploy = ({ commit }) =>
  commit(types.REQUEST_CREATE_DEPLOY);

export const receiveCreateDeploySuccess = ({ commit }, deploy) =>
  commit(types.RECEIVE_CREATE_DEPLOY_SUCCESS, deploy.data);

export const receiveCreateDeployError = ({ commit }, errors) => {
  commit(types.RECEIVE_CREATE_DEPLOY_ERROR, extractErrors(errors));
};

export const createDeploy = ({ state, dispatch }, type) => {
  dispatch("requestCreateDeploy");
  axios
    .post(state.eventUrl, { type })
    .then((response) => {
      dispatch("receiveCreateDeploySuccess", response);
    })
    .catch((errors) => {
      dispatch("receiveCreateDeployError", errors);
    });
};

export const requestCreateCheck = ({ commit }) =>
  commit(types.REQUEST_CREATE_CHECK);

export const receiveCreateCheckSuccess = ({ commit }, check) =>
  commit(types.RECEIVE_CREATE_CHECK_SUCCESS, check.data);

export const receiveCreateCheckError = ({ commit }, errors) => {
  commit(types.RECEIVE_CREATE_CHECK_ERROR, extractErrors(errors));
};

export const createCheck = ({ state, dispatch }, { params }) => {
  dispatch("requestCreateCheck");

  axios
    .post(state.eventUrl, params)
    .then((response) => {
      dispatch("receiveCreateCheckSuccess", response);
    })
    .catch((errors) => {
      dispatch("receiveCreateCheckError", errors);
    });
};

export const requestRenameIntegration = ({ commit }) =>
  commit(types.REQUEST_RENAME_INTEGRATION);

export const receiveRenameIntegrationSuccess = ({ commit }, integration) =>
  commit(types.RECEIVE_RENAME_INTEGRATION_SUCCESS, integration);

export const receiveRenameIntegrationError = ({ commit }, errors) => {
  commit(types.RECEIVE_RENAME_INTEGRATION_ERROR, errors);
};

export const renameIntegration = ({ dispatch }, data) => {
  dispatch("requestRenameIntegration");

  gqlClient
    .mutate({
      mutation: INTEGRATION_NAME_MUTATION,
      variables: data,
    })
    .then((response) => {
      const errors = get(response, "data.integrationUpdate.errors");

      if (errors && errors.length) {
        dispatch("receiveRenameIntegrationError", errors);
      } else {
        const integration = get(response, "data.integrationUpdate.integration");

        dispatch("receiveRenameIntegrationSuccess", integration);
      }
    })
    .catch((errors) => {
      dispatch("receiveRenameIntegrationError", extractApolloErrors(errors));
    });
};

export const syncRepos = ({ commit }, data) => {
  commit(types.REQUEST_SYNC_REPOS_INTEGRATION);

  data.type = "repos";
  gqlClient
    .mutate({
      mutation: INTEGRATION_SYNC_MUTATION,
      variables: data,
    })
    .then((response) => {
      const errors = get(response, "data.integrationSync.errors");

      if (errors && errors.length) {
        commit(types.RECEIVE_SYNC_REPOS_INTEGRATION_ERROR, errors);
      } else {
        const integration = get(response, "data.integrationSync.integration");

        commit(types.RECEIVE_SYNC_REPOS_INTEGRATION_SUCCESS, integration);
      }
    })
    .catch((errors) => {
      commit(
        types.RECEIVE_SYNC_REPOS_INTEGRATION_ERROR,
        extractApolloErrors(errors),
      );
    });
};

export const syncTeams = ({ commit }, data) => {
  commit(types.REQUEST_SYNC_TEAMS_INTEGRATION);

  data.type = "teams";
  gqlClient
    .mutate({
      mutation: INTEGRATION_SYNC_MUTATION,
      variables: data,
    })
    .then((response) => {
      const errors = get(response, "data.integrationSync.errors");

      if (errors && errors.length) {
        commit(types.RECEIVE_SYNC_TEAMS_INTEGRATION_ERROR, errors);
      } else {
        const integration = get(response, "data.integrationSync.integration");

        commit(types.RECEIVE_SYNC_TEAMS_INTEGRATION_SUCCESS, integration);
      }
    })
    .catch((errors) => {
      commit(
        types.RECEIVE_SYNC_TEAMS_INTEGRATION_ERROR,
        extractApolloErrors(errors),
      );
    });
};

export const requestDeauth = ({ commit }) =>
  commit(types.REQUEST_DEAUTH_INTEGRATION);

export const receiveDeauthSuccess = ({ commit }, integration) =>
  commit(types.RECEIVE_DEAUTH_INTEGRATION_SUCCESS, integration);

export const receiveDeauthError = ({ commit }, errors) => {
  commit(types.RECEIVE_DEAUTH_INTEGRATION_ERROR, errors);
};

export const deauth = ({ dispatch }, data) => {
  dispatch("requestDeauth");

  gqlClient
    .mutate({
      mutation: INTEGRATION_DEAUTH_MUTATION,
      variables: data,
    })
    .then((response) => {
      const errors = get(response, "data.integrationDeauth.errors");

      if (errors && errors.length) {
        dispatch("receiveDeauthError", errors);
      } else {
        const integration = get(response, "data.integrationDeauth.integration");

        dispatch("receiveDeauthSuccess", integration);
      }
    })
    .catch((errors) => {
      dispatch("receiveDeauthError", extractApolloErrors(errors));
    });
};

export const syncAlertSources = ({ commit }, data) => {
  commit(types.REQUEST_SYNC_ALERT_SOURCES_INTEGRATION);

  gqlClient
    .mutate({
      mutation: INTEGRATION_SYNC_ALERT_SOURCES_MUTATION,
      variables: data,
    })
    .then((response) => {
      const errors = get(response, "data.integrationSyncAlertSources.errors");

      if (errors && errors.length) {
        commit(types.RECEIVE_SYNC_ALERT_SOURCES_INTEGRATION_ERROR, errors);
      } else {
        const integration = get(
          response,
          "data.integrationSyncAlertSources.integration",
        );

        commit(
          types.RECEIVE_SYNC_ALERT_SOURCES_INTEGRATION_SUCCESS,
          integration,
        );
      }
    })
    .catch((errors) => {
      commit(types.RECEIVE_SYNC_ALERT_SOURCES_INTEGRATION_ERROR, errors);
    });
};

export const requestSetGroup = ({ commit }) => commit(types.REQUEST_SET_GROUP);

export const receiveSetGroupSuccess = ({ commit }, integration) =>
  commit(types.RECEIVE_SET_GROUP_SUCCESS, integration);

export const receiveSetGroupError = ({ commit }, errors) => {
  commit(types.RECEIVE_SET_GROUP_ERROR, errors);
};

export const setGroup = ({ dispatch }, data) => {
  dispatch("requestSetGroup");

  gqlClient
    .mutate({
      mutation: SET_INTEGRATION_GROUP,
      variables: data,
    })
    .then((response) => {
      const errors = get(response, "data.integrationGroupAssign.errors");

      if (errors && errors.length) {
        dispatch("receiveSetGroupError", {
          errors: errors,
          group_id: data.group_id,
        });
      } else {
        const integration = get(
          response,
          "data.integrationGroupAssign.integration",
        );

        dispatch("receiveSetGroupSuccess", integration);
      }
    })
    .catch((errors) => {
      dispatch("receiveSetGroupError", { errors: extractApolloErrors(errors) });
    });
};

export const queryIntegrations = ({ commit }, { fragment, ...args } = {}) => {
  commit(types.REQUEST_QUERY_INTEGRATIONS);

  const namespace = "integrations";
  const lock = acquireLock(namespace);

  const query =
    fragment === "index" ? QUERY_INTEGRATIONS_INDEX : QUERY_INTEGRATIONS;

  gqlClient
    .query({
      query,
      variables: args,
      errorPolicy: "all",
    })
    .then((response) => {
      if (validLock(namespace, lock)) {
        const integrations = get(
          response,
          "data.account.integrations.nodes",
          [],
        );
        const errors = get(response, "errors", []);

        commit(types.RECEIVE_QUERY_INTEGRATIONS_RESPONSE, {
          integrations,
          errors,
        });
      }
    });
};

export const requestCreateIntegration = ({ commit }) =>
  commit(types.REQUEST_CREATE_INTEGRATION);

export const receiveCreateIntegrationSuccess = ({ commit }, integration) =>
  commit(types.RECEIVE_CREATE_INTEGRATION_SUCCESS, integration.data);

export const receiveCreateIntegrationError = ({ commit }, errors) => {
  commit(types.RECEIVE_CREATE_INTEGRATION_ERROR, extractErrors(errors));
};

export const createIntegration = ({ state, dispatch }, params) => {
  dispatch("requestCreateIntegration");
  axios
    .post(state.installUrl, params)
    .then((response) => {
      dispatch("receiveCreateIntegrationSuccess", response);
    })
    .catch((errors) => {
      dispatch("receiveCreateIntegrationError", errors);
    });
};

export const newRelicIntegrationAccounts = ({ commit }, params) => {
  commit(types.REQUEST_NEW_RELIC_ACCOUNTS);

  gqlClient
    .mutate({
      mutation: FETCH_NEW_RELIC_ACCOUNTS,
      variables: params,
    })
    .then((response) => {
      const accounts = get(response, "data.newRelicAccounts.accounts");
      const errors = get(response, "data.newRelicAccounts.errors");

      if (errors && errors.length) {
        commit(types.RECEIVE_NEW_RELIC_ACCOUNTS_ERRORS, errors);
      } else if (accounts) {
        commit(types.RECEIVE_NEW_RELIC_ACCOUNTS_SUCCESS, accounts);
      }
    })
    .catch((error) => {
      commit(
        types.RECEIVE_NEW_RELIC_ACCOUNTS_ERRORS,
        extractApolloErrors(error),
      );
    });
};

export const createNewRelicIntegration = ({ commit }, params) => {
  commit(types.REQUEST_CREATE_INTEGRATION);

  gqlClient
    .mutate({
      mutation: CREATE_NEW_RELIC_INTEGRATION,
      variables: params,
    })
    .then((response) => {
      const integration = get(
        response,
        "data.newRelicIntegrationCreate.integration",
      );
      const errors = get(response, "data.newRelicIntegrationCreate.errors");

      if (errors && errors.length) {
        commit(types.RECEIVE_CREATE_INTEGRATION_ERROR, errors);
      } else if (integration) {
        commit(types.RECEIVE_CREATE_INTEGRATION_SUCCESS, integration);
      }
    })
    .catch((error) => {
      commit(
        types.RECEIVE_CREATE_INTEGRATION_ERROR,
        extractApolloErrors(error),
      );
    });
};

export const updateNewRelicCredentials = ({ commit }, params) => {
  commit(types.REQUEST_UPDATE_INTEGRATION_CREDENTIALS);

  gqlClient
    .mutate({
      mutation: UPDATE_NEW_RELIC_INTEGRATION,
      variables: params,
    })
    .then((response) => {
      const errors = get(response, "data.newRelicIntegrationUpdate.errors");

      if (errors && errors.length) {
        commit(types.RECEIVE_UPDATE_INTEGRATION_CREDENTIALS_ERROR, errors);
      } else {
        commit(types.RECEIVE_UPDATE_INTEGRATION_CREDENTIALS_SUCCESS);
      }
    })
    .catch((error) => {
      commit(
        types.RECEIVE_UPDATE_INTEGRATION_CREDENTIALS_ERROR,
        extractApolloErrors(error),
      );
    });
};

export const updateIntegrationCredentials = ({ commit }, params) => {
  commit(types.REQUEST_UPDATE_INTEGRATION_CREDENTIALS);

  gqlClient
    .mutate({
      mutation: UPDATE_INTEGRATION_CREDENTIALS,
      variables: params,
    })
    .then((response) => {
      const success = get(response, "data.integrationCredentialUpdate.success");
      const errors = get(response, "data.integrationCredentialUpdate.errors");

      if (errors && errors.length) {
        commit(types.RECEIVE_UPDATE_INTEGRATION_CREDENTIALS_ERROR, errors);
      } else if (success) {
        commit(types.RECEIVE_UPDATE_INTEGRATION_CREDENTIALS_SUCCESS);
      }
    })
    .catch((error) => {
      commit(
        types.RECEIVE_UPDATE_INTEGRATION_CREDENTIALS_ERROR,
        extractApolloErrors(error),
      );
    });
};

export const updateDatadogCredentials = ({ commit }, params) => {
  commit(types.REQUEST_UPDATE_INTEGRATION_CREDENTIALS);

  gqlClient
    .mutate({
      mutation: UPDATE_DATADOG_CREDENTIALS,
      variables: params,
    })
    .then((response) => {
      const success = get(response, "data.datadogCredentialsUpdate.success");
      const errors = get(response, "data.datadogCredentialsUpdate.errors");

      if (errors && errors.length) {
        commit(types.RECEIVE_UPDATE_INTEGRATION_CREDENTIALS_ERROR, errors);
      } else if (success) {
        commit(types.RECEIVE_UPDATE_INTEGRATION_CREDENTIALS_SUCCESS);
      }
    })
    .catch((error) => {
      commit(
        types.RECEIVE_UPDATE_INTEGRATION_CREDENTIALS_ERROR,
        extractApolloErrors(error),
      );
    });
};

export const updateDatadogNotificationChannel = ({ commit }, params) => {
  commit(types.REQUEST_UPDATE_DD_NOTIFICATION_CHANNEL);

  gqlClient
    .query({
      query: UPDATE_DD_NOTIFICATION_CHANNEL,
      variables: params,
    })
    .then((response) => {
      const errors = get(response, "data.integrationUpdate.errors");

      if (errors && errors.length) {
        commit(types.RECEIVE_UPDATE_DD_NOTIFICATION_CHANNEL_ERROR, errors);
      } else {
        const integration = get(response, "data.integrationUpdate.integration");

        commit(
          types.RECEIVE_UPDATE_DD_NOTIFICATION_CHANNEL_SUCCESS,
          integration,
        );
      }
    })
    .catch((errors) => {
      commit(
        types.RECEIVE_UPDATE_DD_NOTIFICATION_CHANNEL_ERROR,
        extractApolloErrors(errors),
      );
    });
};

export const updateDatadogSetWebhooksOnMonitors = ({ commit }, params) => {
  commit(types.REQUEST_UPDATE_DD_SET_WEBHOOKS_ON_MONITORS);

  gqlClient
    .query({
      query: UPDATE_DD_SET_WEBHOOKS_ON_MONITORS,
      variables: params,
    })
    .then((response) => {
      const errors = get(response, "data.integrationUpdate.errors");

      if (errors && errors.length) {
        commit(types.RECEIVE_UPDATE_DD_SET_WEBHOOKS_ON_MONITORS_ERROR, errors);
      } else {
        const integration = get(response, "data.integrationUpdate.integration");

        commit(
          types.RECEIVE_UPDATE_DD_SET_WEBHOOKS_ON_MONITORS_SUCCESS,
          integration,
        );
      }
    })
    .catch((errors) => {
      commit(
        types.RECEIVE_UPDATE_DD_SET_WEBHOOKS_ON_MONITORS_ERROR,
        extractApolloErrors(errors),
      );
    });
};

export const updateIntegrationBaseUrl = ({ commit }, data) => {
  commit(types.REQUEST_UPDATE_INTEGRATION_BASE_URL);

  gqlClient
    .mutate({
      mutation: UPDATE_INTEGRATION_BASE_URL,
      variables: data,
    })
    .then((response) => {
      const errors = get(response, "data.integrationUpdate.errors");

      if (errors && errors.length) {
        commit(types.RECEIVE_UPDATE_INTEGRATION_BASE_URL_ERROR, errors);
      } else {
        const integration = get(response, "data.integrationUpdate.integration");

        commit(types.RECEIVE_UPDATE_INTEGRATION_BASE_URL_SUCCESS, integration);
      }
    })
    .catch((errors) => {
      commit(
        types.RECEIVE_UPDATE_INTEGRATION_BASE_URL_ERROR,
        extractApolloErrors(errors),
      );
    });
};

export const updateIntegrationAllowedRepoVisibility = ({ commit }, data) => {
  commit(types.REQUEST_UPDATE_INTEGRATION_ALLOWED_REPO_VISIBILITY);

  gqlClient
    .mutate({
      mutation: UPDATE_INTEGRATION_ALLOWED_REPO_VISIBILITY,
      variables: data,
    })
    .then((response) => {
      const errors = get(response, "data.integrationUpdate.errors");

      if (errors && errors.length) {
        commit(
          types.RECEIVE_UPDATE_INTEGRATION_ALLOWED_REPO_VISIBILITY_ERROR,
          errors,
        );
      } else {
        const integration = get(response, "data.integrationUpdate.integration");

        commit(
          types.RECEIVE_UPDATE_INTEGRATION_ALLOWED_REPO_VISIBILITY_SUCCESS,
          integration,
        );
      }
    })
    .catch((errors) => {
      commit(
        types.RECEIVE_UPDATE_INTEGRATION_ALLOWED_REPO_VISIBILITY_ERROR,
        extractApolloErrors(errors),
      );
    });
};

export const updateIntegrationServiceDiscoveryEnabled = (
  { commit },
  { args },
) => {
  commit(types.REQUEST_UPDATE_INTEGRATION_SERVICE_DISCOVERY_ENABLED);

  gqlClient
    .mutate({
      mutation: UPDATE_INTEGRATION_SERVICE_DISCOVERY_ENABLED,
      variables: args,
    })
    .then((response) => {
      const errors = get(response, "data.integrationUpdate.errors");

      if (errors && errors.length) {
        commit(
          types.RECEIVE_UPDATE_INTEGRATION_SERVICE_DISCOVERY_ENABLED_ERROR,
          errors,
        );
      } else {
        const integration = get(response, "data.integrationUpdate.integration");

        commit(
          types.RECEIVE_UPDATE_INTEGRATION_SERVICE_DISCOVERY_ENABLED_SUCCESS,
          { integration },
        );
      }
    })
    .catch((errors) => {
      commit(
        types.RECEIVE_UPDATE_INTEGRATION_SERVICE_DISCOVERY_ENABLED_ERROR,
        extractApolloErrors(errors),
      );
    });
};

export const updateIntegrationSBOMGenerationEnabled = (
  { commit },
  { args },
) => {
  commit(types.REQUEST_UPDATE_INTEGRATION_SBOM_GENERATION_ENABLED);

  gqlClient
    .mutate({
      mutation: UPDATE_INTEGRATION_SBOM_GENERATION_ENABLED,
      variables: args,
    })
    .then((response) => {
      const errors = get(response, "data.integrationUpdate.errors");

      if (errors && errors.length) {
        commit(
          types.RECEIVE_UPDATE_INTEGRATION_SBOM_GENERATION_ENABLED_ERROR,
          errors,
        );
      } else {
        const integration = get(response, "data.integrationUpdate.integration");

        commit(
          types.RECEIVE_UPDATE_INTEGRATION_SBOM_GENERATION_ENABLED_SUCCESS,
          { integration },
        );
      }
    })
    .catch((errors) => {
      commit(
        types.RECEIVE_UPDATE_INTEGRATION_SBOM_GENERATION_ENABLED_ERROR,
        extractApolloErrors(errors),
      );
    });
};

export const updateIntegrationServiceDiscoveryIgnoreRules = (
  { commit },
  { args },
) => {
  commit(types.REQUEST_UPDATE_INTEGRATION_SERVICE_DISCOVERY_IGNORE_RULES);

  gqlClient
    .mutate({
      mutation: UPDATE_INTEGRATION_SERVICE_DISCOVERY_IGNORE_RULES,
      variables: args,
    })
    .then((response) => {
      const errors = get(response, "data.integrationUpdate.errors");

      if (errors && errors.length) {
        commit(
          types.RECEIVE_UPDATE_INTEGRATION_SERVICE_DISCOVERY_IGNORE_RULES_ERROR,
          errors,
        );
      } else {
        const integration = get(response, "data.integrationUpdate.integration");

        commit(
          types.RECEIVE_UPDATE_INTEGRATION_SERVICE_DISCOVERY_IGNORE_RULES_SUCCESS,
          { integration },
        );
      }
    })
    .catch((errors) => {
      commit(
        types.RECEIVE_UPDATE_INTEGRATION_SERVICE_DISCOVERY_IGNORE_RULES_ERROR,
        extractApolloErrors(errors),
      );
    });
};

export const updateIntegrationTeamSync = ({ commit }, data) => {
  commit(types.REQUEST_UPDATE_INTEGRATION_TEAM_SYNC);

  gqlClient
    .mutate({
      mutation: UPDATE_INTEGRATION_TEAM_SYNC,
      variables: data,
    })
    .then((response) => {
      const errors = get(response, "data.integrationUpdate.errors");

      if (errors && errors.length) {
        commit(types.RECEIVE_UPDATE_INTEGRATION_TEAM_SYNC_ERRORS, errors);
      } else {
        const integration = get(response, "data.integrationUpdate.integration");

        commit(types.RECEIVE_UPDATE_INTEGRATION_TEAM_SYNC_SUCCESS, integration);
      }
    })
    .catch((errors) => {
      commit(
        types.RECEIVE_UPDATE_INTEGRATION_TEAM_SYNC_ERRORS,
        extractApolloErrors(errors),
      );
    });
};

export const updateIntegrationWarning = ({ commit }, data) => {
  commit(types.REQUEST_UPDATE_INTEGRATION_WARNING);

  gqlClient
    .mutate({
      mutation: UPDATE_INTEGRATION_WARNING,
      variables: data,
    })
    .then((response) => {
      const errors = get(response, "data.integrationWarningUpdate.errors");

      if (errors && errors.length) {
        commit(types.RECEIVE_UPDATE_INTEGRATION_WARNING_ERRORS, errors);
      } else {
        const integration = get(
          response,
          "data.integrationWarningUpdate.integration",
        );

        commit(types.RECEIVE_UPDATE_INTEGRATION_WARNING_SUCCESS, integration);
      }
    })
    .catch((errors) => {
      commit(
        types.RECEIVE_UPDATE_INTEGRATION_WARNING_ERRORS,
        extractApolloErrors(errors),
      );
    });
};

export const updateIncidentIoIntegrationCustomFieldMapping = (
  { commit },
  params,
) => {
  commit(types.REQUEST_UPDATE_INTEGRATION_CONFIG_MAPPING);

  gqlClient
    .mutate({
      mutation: UPDATE_INCIDENT_IO_INTEGRATION,
      variables: params,
    })
    .then((response) => {
      const integration = get(
        response,
        "data.incidentIoIntegrationUpdate.integration",
      );
      const errors = get(response, "data.incidentIoIntegrationUpdate.errors");

      if (errors && errors.length) {
        commit(types.RECEIVE_UPDATE_INTEGRATION_CONFIG_MAPPING_ERROR, errors);
      } else {
        commit(
          types.RECEIVE_UPDATE_INTEGRATION_CONFIG_MAPPING_SUCCESS,
          integration,
        );
      }
    })
    .catch((error) => {
      commit(
        types.RECEIVE_UPDATE_INTEGRATION_CONFIG_MAPPING_ERROR,
        extractApolloErrors(error),
      );
    });
};

export const deleteGitForgeIntegration = ({ commit }, data) => {
  commit(types.REQUEST_DELETE_INTEGRATION);

  gqlClient
    .mutate({
      mutation: DELETE_GITFORGE_INTEGRATION_MUTATION,
      variables: data,
    })
    .then((response) => {
      const errors = get(response, "data.gitForgeIntegrationDelete.errors");

      if (errors && errors.length) {
        commit(types.RECEIVE_DELETE_INTEGRATION_ERRORS, errors);
      } else {
        const integration = get(
          response,
          "data.gitForgeIntegrationDelete.integration",
        );

        commit(types.RECEIVE_DELETE_INTEGRATION_SUCCESS, integration);
      }
    })
    .catch((errors) => {
      commit(
        types.RECEIVE_DELETE_INTEGRATION_ERRORS,
        extractApolloErrors(errors),
      );
    });
};

export const deleteIntegration = ({ commit }, data) => {
  commit(types.REQUEST_DELETE_INTEGRATION);

  gqlClient
    .mutate({
      mutation: DELETE_INTEGRATION_MUTATION,
      variables: data,
    })
    .then((response) => {
      const errors = get(response, "data.integrationDelete.errors");

      if (errors && errors.length) {
        commit(types.RECEIVE_DELETE_INTEGRATION_ERRORS, errors);
      } else {
        const integration = get(response, "data.integrationDelete.integration");

        commit(types.RECEIVE_DELETE_INTEGRATION_SUCCESS, integration);
      }
    })
    .catch((errors) => {
      commit(
        types.RECEIVE_DELETE_INTEGRATION_ERRORS,
        extractApolloErrors(errors),
      );
    });
};

export const validateIntegrationCredentials = (
  { commit },
  { type, options },
) => {
  commit(types.REQUEST_VALIDATE_INTEGRATION_CREDENTIALS);

  gqlClient
    .mutate({
      mutation: VALIDATE_INTEGRATION_CREDENTIALS,
      variables: { type: type, options: options },
    })
    .then((response) => {
      const errors = get(
        response,
        "data.integrationValidateCredentials.errors",
      );
      const organizations = get(
        response,
        "data.integrationValidateCredentials.organizations",
      );
      const user = get(response, "data.integrationValidateCredentials.user");
      const tokenOwnerChange = get(
        response,
        "data.integrationValidateCredentials.tokenOwnerChange",
      );

      commit(types.RECEIVE_VALIDATE_INTEGRATION_CREDENTIALS_SUCCESS, {
        errors,
        organizations,
        user,
        tokenOwnerChange,
      });
    })
    .catch((errors) => {
      commit(
        types.RECEIVE_VALIDATE_INTEGRATION_CREDENTIALS_ERRORS,
        extractApolloErrors(errors),
      );
    });
};

export const fetchGenericIntegrations = ({ commit }) => {
  commit(types.REQUEST_GENERIC_INTEGRATIONS);

  const query = {
    query: FETCH_GENERIC_INTEGRATIONS,
    variables: null,
  };

  const handleSuccess = function (response) {
    const integrations = get(
      response,
      "data.account.availableIntegrations",
      [],
    );

    commit(types.RECEIVE_GENERIC_INTEGRATIONS_SUCCESS, integrations);
  };

  const handleErrors = function (errors) {
    commit(types.RECEIVE_GENERIC_INTEGRATIONS_ERRORS, errors);
  };

  gqlClient.query(query).then(handleSuccess).catch(handleErrors);
};

export const fetchInfrastructureIntegrationDetails = ({ commit }, params) => {
  commit(types.REQUEST_INTEGRATION);

  gqlClient
    .query({
      query: FETCH_INFRA_INTEGRATION_DETAILS,
      variables: params,
    })
    .then((response) => {
      const integration = get(response, "data.account.integration");

      commit(types.RECEIVE_INTEGRATION_SUCCESS, { integration });
    })
    .catch((errors) => {
      commit(types.RECEIVE_INTEGRATION_ERRORS, extractApolloErrors(errors));
    });
};

export const fetchIntegrationShowDetails = ({ commit }, params) => {
  commit(types.REQUEST_INTEGRATION);

  gqlClient
    .query({
      query: FETCH_INTREGRATION_SHOW_DETAILS,
      variables: params,
    })
    .then((response) => {
      const integration = get(response, "data.account.integration");

      commit(types.RECEIVE_INTEGRATION_SUCCESS, { integration });
    })
    .catch((errors) => {
      commit(types.RECEIVE_INTEGRATION_ERRORS, extractApolloErrors(errors));
    });
};

export const subscribeToIntegrationDetails = ({ commit }, params) => {
  commit(types.REQUEST_INTEGRATION);
  gqlClient
    .subscribe({
      query: SUBSCRIBE_INTEGRATION_DETAILS,
      variables: params,
    })
    .subscribe((event) => {
      const integration = get(event, "data.integrationStatusUpdated");

      commit(types.RECEIVE_INTEGRATION_SUCCESS, { integration });
    });
};

export const fetchIntegrationDetails = ({ commit }, params) => {
  commit(types.REQUEST_INTEGRATION);

  gqlClient
    .query({
      query: FETCH_INTEGRATION_DETAILS,
      variables: params,
    })
    .then((response) => {
      const integration = get(response, "data.account.integration");

      commit(types.RECEIVE_INTEGRATION_SUCCESS, { integration });
    })
    .catch((errors) => {
      commit(types.RECEIVE_INTEGRATION_ERRORS, extractApolloErrors(errors));
    });
};

export const createSampleGitIntegration = ({ commit }, params) => {
  commit(types.REQUEST_CREATE_SAMPLE_GIT_INTEGRATION);

  gqlClient
    .mutate({
      mutation: CREATE_SAMPLE_GIT_INTEGRATION,
      variables: params,
    })
    .then((response) => {
      const integration = get(
        response,
        "data.sampleIntegrationCreate.integration",
      );
      const errors = get(response, "data.sampleIntegrationCreate.errors");

      if (errors && errors.length) {
        commit(types.RECEIVE_CREATE_SAMPLE_GIT_INTEGRATION_ERRORS, errors);
      } else if (integration) {
        commit(
          types.RECEIVE_CREATE_SAMPLE_GIT_INTEGRATION_SUCCESS,
          integration,
        );
      }
    })
    .catch((error) => {
      commit(
        types.RECEIVE_CREATE_SAMPLE_GIT_INTEGRATION_ERRORS,
        extractApolloErrors(error),
      );
    });
};

export const syncIntegrationInfra = ({ commit }, { id }) => {
  commit(types.REQUEST_SYNC_INTEGRATION);

  gqlClient
    .mutate({
      mutation: INTEGRATION_SYNC_MUTATION,
      variables: { id, type: "infra" },
    })
    .then((response) => {
      const errors = get(response, "data.integrationSync.errors");

      if (errors && errors.length) {
        commit(types.RECEIVE_SYNC_INTEGRATION_ERROR, { errors });
      } else {
        commit(types.RECEIVE_SYNC_INTEGRATION_SUCCESS);
      }
    })
    .catch((errors) => {
      commit(types.RECEIVE_SYNC_INTEGRATION_ERROR, {
        errors: extractApolloErrors(errors),
      });
    });
};

export const createAwsIntegration = ({ commit }, params) => {
  commit(types.REQUEST_CREATE_INTEGRATION);

  gqlClient
    .mutate({
      mutation: CREATE_AWS_INTEGRATION,
      variables: params,
    })
    .then((response) => {
      const integration = get(
        response,
        "data.awsIntegrationCreate.integration",
      );
      const errors = get(response, "data.awsIntegrationCreate.errors");

      if (errors && errors.length) {
        commit(types.RECEIVE_CREATE_INTEGRATION_ERROR, errors);
      } else if (integration) {
        commit(types.RECEIVE_CREATE_INTEGRATION_SUCCESS, integration);
      }
    })
    .catch((error) => {
      commit(
        types.RECEIVE_CREATE_INTEGRATION_ERROR,
        extractApolloErrors(error),
      );
    });
};

export const createAzureResourcesIntegration = ({ commit }, params) => {
  commit(types.REQUEST_CREATE_INTEGRATION);

  gqlClient
    .mutate({
      mutation: CREATE_AZURE_RESOURCES_INTEGRATION,
      variables: params,
    })
    .then((response) => {
      const integration = get(
        response,
        "data.azureResourcesIntegrationCreate.integration",
      );
      const errors = get(
        response,
        "data.azureResourcesIntegrationCreate.errors",
      );

      if (errors && errors.length) {
        commit(types.RECEIVE_CREATE_INTEGRATION_ERROR, errors);
      } else if (integration) {
        commit(types.RECEIVE_CREATE_INTEGRATION_SUCCESS, integration);
      }
    })
    .catch((error) => {
      commit(
        types.RECEIVE_CREATE_INTEGRATION_ERROR,
        extractApolloErrors(error),
      );
    });
};

export const createGoogleCloudIntegration = ({ commit }, params) => {
  commit(types.REQUEST_CREATE_INTEGRATION);

  gqlClient
    .mutate({
      mutation: CREATE_GOOGLE_CLOUD_INTEGRATION,
      variables: params,
    })
    .then((response) => {
      const integration = get(
        response,
        "data.googleCloudIntegrationCreate.integration",
      );
      const errors = get(response, "data.googleCloudIntegrationCreate.errors");

      if (errors && errors.length) {
        commit(types.RECEIVE_CREATE_INTEGRATION_ERROR, errors);
      } else if (integration) {
        commit(types.RECEIVE_CREATE_INTEGRATION_SUCCESS, integration);
      }
    })
    .catch((error) => {
      commit(
        types.RECEIVE_CREATE_INTEGRATION_ERROR,
        extractApolloErrors(error),
      );
    });
};

export const createSnykIntegration = ({ commit }, params) => {
  commit(types.REQUEST_CREATE_INTEGRATION);

  gqlClient
    .mutate({
      mutation: CREATE_SNYK_INTEGRATION,
      variables: params,
    })
    .then((response) => {
      const integration = get(
        response,
        "data.snykIntegrationCreate.integration",
      );
      const errors = get(response, "data.snykIntegrationCreate.errors");

      if (errors && errors.length) {
        commit(types.RECEIVE_CREATE_INTEGRATION_ERROR, errors);
      } else if (integration) {
        commit(types.RECEIVE_CREATE_INTEGRATION_SUCCESS, integration);
      }
    })
    .catch((error) => {
      commit(
        types.RECEIVE_CREATE_INTEGRATION_ERROR,
        extractApolloErrors(error),
      );
    });
};

export const createSonarqubeIntegration = ({ commit }, params) => {
  commit(types.REQUEST_CREATE_INTEGRATION);

  gqlClient
    .mutate({
      mutation: CREATE_SONARQUBE_INTEGRATION,
      variables: params,
    })
    .then((response) => {
      const integration = get(
        response,
        "data.sonarqubeIntegrationCreate.integration",
      );
      const errors = get(response, "data.sonarqubeIntegrationCreate.errors");

      if (errors && errors.length) {
        commit(types.RECEIVE_CREATE_INTEGRATION_ERROR, errors);
      } else if (integration) {
        commit(types.RECEIVE_CREATE_INTEGRATION_SUCCESS, integration);
      }
    })
    .catch((error) => {
      commit(
        types.RECEIVE_CREATE_INTEGRATION_ERROR,
        extractApolloErrors(error),
      );
    });
};

export const createSonarqubeCloudIntegration = ({ commit }, params) => {
  commit(types.REQUEST_CREATE_INTEGRATION);

  gqlClient
    .mutate({
      mutation: CREATE_SONARQUBE_CLOUD_INTEGRATION,
      variables: params,
    })
    .then((response) => {
      const integration = get(
        response,
        "data.sonarqubeCloudIntegrationCreate.integration",
      );
      const errors = get(
        response,
        "data.sonarqubeCloudIntegrationCreate.errors",
      );

      if (errors && errors.length) {
        commit(types.RECEIVE_CREATE_INTEGRATION_ERROR, errors);
      } else if (integration) {
        commit(types.RECEIVE_CREATE_INTEGRATION_SUCCESS, integration);
      }
    })
    .catch((error) => {
      commit(
        types.RECEIVE_CREATE_INTEGRATION_ERROR,
        extractApolloErrors(error),
      );
    });
};

export const createIncidentIoIntegration = ({ commit }, params) => {
  commit(types.REQUEST_CREATE_INTEGRATION);

  gqlClient
    .mutate({
      mutation: CREATE_INCIDENT_IO_INTEGRATION,
      variables: params,
    })
    .then((response) => {
      const integration = get(
        response,
        "data.incidentIoIntegrationCreate.integration",
      );
      const errors = get(response, "data.incidentIoIntegrationCreate.errors");

      if (errors && errors.length) {
        commit(types.RECEIVE_CREATE_INTEGRATION_ERROR, errors);
      } else if (integration) {
        commit(types.RECEIVE_CREATE_INTEGRATION_SUCCESS, integration);
      }
    })
    .catch((error) => {
      commit(
        types.RECEIVE_CREATE_INTEGRATION_ERROR,
        extractApolloErrors(error),
      );
    });
};

export const createOctopusDeployIntegration = ({ commit }, params) => {
  commit(types.REQUEST_CREATE_INTEGRATION);

  gqlClient
    .mutate({
      mutation: CREATE_OCTOPUS_DEPLOY_INTEGRATION,
      variables: params,
    })
    .then((response) => {
      const integration = get(
        response,
        "data.octopusDeployIntegrationCreate.integration",
      );
      const errors = get(
        response,
        "data.octopusDeployIntegrationCreate.errors",
      );

      if (errors && errors.length) {
        commit(types.RECEIVE_CREATE_INTEGRATION_ERROR, errors);
      } else if (integration) {
        commit(types.RECEIVE_CREATE_INTEGRATION_SUCCESS, integration);
      }
    })
    .catch((error) => {
      commit(
        types.RECEIVE_CREATE_INTEGRATION_ERROR,
        extractApolloErrors(error),
      );
    });
};

export const updateOctopusDeployIntegration = ({ commit }, params) => {
  commit(types.REQUEST_UPDATE_OCTOPUS);

  gqlClient
    .mutate({
      mutation: UPDATE_OCTOPUS_DEPLOY_INTEGRATION,
      variables: params,
    })
    .then((response) => {
      const integration = get(
        response,
        "data.octopusDeployIntegrationUpdate.integration",
      );

      const errors = get(
        response,
        "data.octopusDeployIntegrationUpdate.errors",
      );

      if (errors && errors.length) {
        commit(types.RECEIVE_UPDATE_OCTOPUS_ERRORS, errors);
      } else {
        commit(types.RECEIVE_UPDATE_OCTOPUS_SUCCESS, { integration });
      }
    })
    .catch((error) => {
      commit(types.RECEIVE_UPDATE_OCTOPUS_ERRORS, extractApolloErrors(error));
    });
};
