<template>
  <a-row v-if="hasTeamUpdateAccess" :style="style">
    <a-row>
      <div style="margin: auto; text-align: center">
        <img
          src="/status_images/no_team_members.svg"
          style="height: 200px; width: 200px; padding-bottom: 10px"
          class="call-to-action-image"
        />
        <p style="font-size: 20px; margin-bottom: 5px">
          What teams are you on?
        </p>
        <p style="margin-bottom: 0px">
          Select which teams you're on in OpsLevel to get a personalized view of
          what {{ $t("components") }} you're responsible for.
        </p>
      </div>
    </a-row>
    <a-row>
      <a-col style="padding: 15px">
        <a-input
          v-model="searchTerm"
          placeholder="Search by team or manager name."
          style="margin-bottom: 15px"
        />
        <a-table
          v-if="teams"
          :columns="columns"
          :dataSource="filteredTeams"
          :loading="isFetchingTeams || isModifyingMembers"
          :rowKey="(team) => team.id"
          :pagination="false"
          :scroll="{ y: scrollHeight }"
          :locale="{ emptyText: 'No teams match' }"
          size="small"
        >
          <span slot="name" slot-scope="team">
            {{ team.name }}
          </span>
          <span slot="manager" slot-scope="team">
            <span v-if="team.manager">{{ team.manager.name }}</span>
          </span>
          <span
            slot="actions"
            slot-scope="team"
            style="display: flex; justify-items: center; align-items: center"
          >
            <a-switch
              checked-children="Joined"
              style="margin: auto; position: relative"
              :checked="joinedTeams.indexOf(team.id) >= 0"
              :disabled="!team.permissions.canUpdate"
              @change="(checked) => toggleTeam(checked, team.id)"
            />
          </span>
        </a-table>
      </a-col>
      <div v-if="hasTeamUpdateAccess && !hideButtons" style="text-align: right">
        <a-button v-if="canCreateTeams" @click="createTeam">
          Create a New Team
        </a-button>
        <a-button
          key="submit"
          type="primary"
          :disabled="joinedTeams.length === 0"
          @click="fetchData"
        >
          See dashboard
        </a-button>
      </div>
    </a-row>
  </a-row>
  <a-row v-else>
    <a-row>
      <div style="margin: auto; text-align: center">
        <img
          src="/status_images/no_team_members.svg"
          style="height: 200px; width: 200px; padding-bottom: 10px"
          class="call-to-action-image"
        />
        <p style="font-size: 20px; margin-bottom: 5px">
          You're not on any teams yet!
        </p>
        <p style="margin-bottom: 0px">
          To view the {{ $t("components") }} and systems you own, you need join
          a team in OpsLevel. Unfortunately, you do not have permission to add
          yourself directly to a team. Please contact an admin or manager to get
          started.
        </p>
        <p style="margin-top: 5px">
          In the meantime, you can explore your organization's teams and
          software in OpsLevel.
        </p>
      </div>
      <div v-if="!hideButtons" style="margin-top: 20px; text-align: center">
        <a-button @click="redirectToServices">
          Take me to {{ $t("Components") }}
        </a-button>
        <a-button @click="redirectToTeams"> Take me to Teams </a-button>
      </div>
    </a-row>
  </a-row>
</template>

<script>
import { mapActions, mapState } from "vuex";
import store from "@/store/index.js";
import { localeComparator } from "@/shared/comparators.js";
import { actorPermissionsMixin } from "@/mixins/actorPermissionsMixin.js";
import teams from "@/modules/teams/index.js";
import members from "@/modules/teams/members/index.js";

function defaultColumns() {
  return {
    name: {
      title: "Team",
      key: "name",
      scopedSlots: { customRender: "name" },
      sorter: localeComparator("name"),
      defaultSortOrder: "ascend",
    },
    manager: {
      title: "Manager",
      key: "manager",
      scopedSlots: { customRender: "manager" },
      sorter: localeComparator("manager.name"),
      defaultSortOrder: "ascend",
    },
    actions: {
      title: "",
      key: "",
      scopedSlots: { customRender: "actions" },
    },
  };
}

export default {
  store,
  mixins: [actorPermissionsMixin],
  inject: ["routes"],

  props: {
    currentUser: {
      type: Object,
      required: true,
    },
    height: {
      type: String,
      required: false,
      default: null,
    },
    scrollHeight: {
      type: Number,
      required: false,
      default: 340,
    },
    fetchData: {
      type: Function,
      required: false,
      default: () => {},
    },
    hideButtons: {
      type: Boolean,
      required: false,
      default: true,
    },
  },

  data() {
    return {
      searchTerm: "",
    };
  },

  computed: {
    ...mapState({
      teams: (state) => state.joinTeams.teams,
      isFetchingTeams: (state) => state.joinTeams.isFetchingTeams,
      isModifyingMembers: (state) => state.members.isModifyingMembers,
      joinedTeams: (state) => state.members.joinedTeams,
      actionedTeam: (state) => state.members.actionedTeam,
    }),
    filteredTeams() {
      const searchTerm = this.searchTerm.toLowerCase();

      return this.teams.filter((t) => {
        if (this.searchTerm === "") {
          return true;
        }

        return (
          t.name.toLowerCase().includes(searchTerm) ||
          (t.manager && t.manager.name.toLowerCase().includes(searchTerm))
        );
      });
    },
    canCreateTeams() {
      return this.hasActorPermission("team", "create");
    },
    hasTeamUpdateAccess() {
      return this.canCreateTeams || this.hasUpdateableTeams;
    },
    hasUpdateableTeams() {
      return this.teams?.some((team) => team.permissions.canUpdate);
    },
    columns() {
      return Object.values(defaultColumns());
    },
    style() {
      return this.height
        ? `height: ${this.height}; overflow: hidden;`
        : "overflow: hidden;";
    },
  },

  mounted() {
    this.$store.watch(
      (state) => state.members.errorsCreatingMembers,
      (errorsCreatingMembers) => {
        if (errorsCreatingMembers.length) {
          const team = this.actedUponTeam(this.actionedTeam);

          this.$message.error(
            this.$createElement("span", [
              "There was an error adding you to team ",
              this.$createElement("strong", `${team.name}`),
              ".",
            ]),
            6,
          );
        }
      },
    );

    this.$store.watch(
      (state) => state.members.errorsDeletingMembers,
      (errorsDeletingMembers) => {
        if (errorsDeletingMembers.length) {
          const team = this.actedUponTeam(this.actionedTeam);

          this.$message.error(
            this.$createElement("span", [
              "There was an error removing you from team ",
              this.$createElement("strong", `${team.name}`),
              ".",
            ]),
            6,
          );
        }
      },
    );
  },

  created() {
    this.$store.registerModuleOnce("joinTeams", teams);
    this.$store.registerModuleOnce("members", members);
    this.fetchTeams({ withManager: true });
  },

  methods: {
    ...mapActions("members", [
      "createTeamMemberships",
      "deleteTeamMemberships",
    ]),
    ...mapActions("joinTeams", ["fetchTeams"]),
    actedUponTeam(teamId) {
      return this.teams.find((t) => t.id === teamId);
    },
    toggleTeam(checked, teamId) {
      const params = {
        teamId: teamId,
        members: [{ email: this.currentUser.user.email }],
      };

      if (checked) {
        this.createTeamMemberships({ params });
      } else {
        this.deleteTeamMemberships({ params });
      }
    },
    redirectToServices() {
      window.location.href = this.routes.services_path();
    },
    redirectToTeams() {
      window.location.href = this.routes.teams_path();
    },
    createTeam() {
      window.location = this.routes.new_team_path();
    },
  },
};
</script>
