export const ARGOCD_DEPLOY_INTEGRATION_DOC =
  "https://docs.opslevel.com/docs/argocd";
export const CIRCLECI_DEPLOY_INTEGRATION_DOC =
  "https://docs.opslevel.com/docs/circleci";
export const CLI_INTEGRATION_DOC = "https://docs.opslevel.com/docs/api/cli/";
export const CLI_DEPLOY_INTEGRATION_DOC =
  "https://docs.opslevel.com/docs/api/cli/#deploys";
export const CUSTOM_CHECK_DOC = "https://docs.opslevel.com/docs/payload-checks";
export const CUSTOM_EVENT_CHECK_DOC =
  "https://docs.opslevel.com/docs/checks/custom-event/";
export const DATADOG_INTEGRATION_DOC =
  "https://docs.opslevel.com/docs/integrations/datadog/";
export const DEPLOY_INTEGRATION_DOC =
  "https://docs.opslevel.com/docs/insights/deploys/";
export const DOCUMENTATION_URL = "https://docs.opslevel.com/docs/";
export const GIT_INTEGRATION_DOC =
  "https://docs.opslevel.com/docs/git-integration";
export const GITHUB_INTEGRATION_DOC =
  "https://docs.opslevel.com/docs/github-integration";
export const GITHUB_ACTIONS_INTEGRATION_DOC =
  "https://docs.opslevel.com/docs/github-actions-integration";
export const GITLAB_CI_INTEGRATION_DOC =
  "https://docs.opslevel.com/docs/gitlab-ci-integration";
export const GITLAB_SELF_MANAGED_INTEGRATION_DOC =
  "https://docs.opslevel.com/docs/gitlab-self-managed";
export const HOMEPAGE_URL = "https://www.opslevel.com";
export const JENKINS_INTEGRATION_DOC =
  "https://docs.opslevel.com/docs/jenkins-integration";
export const OCTOPUS_DEPLOY_INTEGRATION_DOC =
  "https://docs.opslevel.com/docs/octopus-deploy-integration";
export const OPSLEVEL_YAML_DOC = "https://docs.opslevel.com/docs/opslevel-yml/";
export const PAGERDUTY_INTEGRATION_DOC =
  "https://docs.opslevel.com/docs/pagerduty/";
export const PAYLOAD_CHECK_DOC =
  "https://docs.opslevel.com/docs/checks/payload-checks/";
export const REPO_FILE_CHECK_DOC =
  "https://docs.opslevel.com/docs/repo-file-checks/#using-a-version-constraint-advanced";
export const REPO_GREP_CHECK_DOC =
  "https://docs.opslevel.com/docs/repo-grep-checks";
export const SLACK_INTEGRATION_DOC =
  "https://docs.opslevel.com/docs/slack-integration/";
export const TAG_DEFINED_CHECK_DOC =
  "https://docs.opslevel.com/docs/tag-defined-checks/#verify-library-version";
export const KUBERNETES_INTEGRATION_DOC =
  "https://docs.opslevel.com/docs/kubernetes-integration/";
export const TERRAFORM_API_DOC = "https://docs.opslevel.com/docs/terraform/";
export const MATURITY_BADGE_DOC =
  "https://docs.opslevel.com/docs/service-level-badges";
export const JIRA_INTEGRATION_DOC =
  "https://docs.opslevel.com/docs/jira-software";
export const API_DOCS_INTEGRATION_DOC =
  "https://docs.opslevel.com/docs/api-docs/";
export const OPSGENIE_INTEGRATION_DOC =
  "https://docs.opslevel.com/docs/opsgenie/";
export const INTEGRATIONS_URL = "/integrations";
export const EXPLAIN_GLOBBING_URL =
  "https://www.baeldung.com/linux/bash-globbing";
export const AZURE_DEVOPS_INTEGRATION_DOC =
  "https://docs.opslevel.com/docs/azure-devops";
export const OKTA_SCIM_INTEGRATION_DOC =
  "https://docs.opslevel.com/docs/okta-user-provisioning-with-scim";
export const INFRASTRUCTURE_INTEGRATION_DOC =
  "https://docs.opslevel.com/docs/infrastructure";
export const NEW_RELIC_INTEGRATION_DOC =
  "https://docs.opslevel.com/docs/new-relic-integration";
export const LEVELS_DOC =
  "https://docs.opslevel.com/docs/categories-and-levels#levels";
export const DEMO_LINK =
  "https://opslevel.na.chilipiper.com/book/custom-demo-request";
export const AWS_INTEGRATION_DOC =
  "https://docs.opslevel.com/docs/import-infrastructure-objects-via-aws";
export const AZURE_RESOURCES_INTEGRATION_DOC =
  "https://docs.opslevel.com/docs/import-infrastructure-objects-via-azure";
export const GOOGLE_CLOUD_INTEGRATION_DOC =
  "https://docs.opslevel.com/docs/import-infrastructure-objects-via-google";
export const SNYK_INTEGRATION_DOC =
  "https://docs.opslevel.com/docs/snyk-integration";
export const SONARQUBE_INTEGRATION_DOC =
  "https://docs.opslevel.com/docs/sonarqube-integration";
export const SONARQUBE_CLOUD_INTEGRATION_DOC =
  "https://docs.opslevel.com/docs/sonarqube-cloud-integration";
export const MICROSOFT_TEAMS_INTEGRATION_DOC =
  "https://docs.opslevel.com/docs/microsoft-teams";
export const INCIDENT_IO_INTEGRATION_DOC =
  "https://docs.opslevel.com/docs/incident-io";
