import { gql } from "@apollo/client";

export const BASIC_INTEGRATION_FRAGMENT = gql`
  fragment BasicIntegrationFragment on Integration {
    id
    name
    shortName
    type
    accountKey
    notificationChannel
    setWebhooksOnMonitors
    baseUrl
    ignoredPatterns
    href
    serviceDiscoveryEnabled
    earlyAccess

    warnings {
      id
      message
      createdAt
      dismissed
    }

    ... on ManualAlertSourceSync {
      lastManualSyncAlertSources
      allowManualSyncAlertSources
    }
    ... on AzureDevopsIntegration {
      permissionsErrors {
        name
        type
        permissions
      }
      accountErrors
    }
    ... on AzureResourcesIntegration {
      tenantId
      subscriptionId
      lastSyncedAt
      aliases
      allowManualSyncInfrastructureResources
      minutesUntilManualSyncInfrastructureResourcesAllowed
    }
    ... on SonarqubeCloudIntegration {
      organizationKey
    }
    ... on IncidentIoIntegration {
      mappedCustomField {
        externalId
        name
        catalogTypeName
        eligible
      }
    }
  }
`;

export const INTEGRATIONS_INDEX_FRAGMENT = gql`
  fragment IntegrationsIndexFragment on Integration {
    id
    active
    name
    displayName
    type
    accountName
    accountUrl
    baseUrl
    href
    version
    createdAt
    stillLoading
    invalidatedAt
    uninstallInProgress
    stale
    lastEventReceived
    earlyAccess

    categories {
      key
      displayName
    }
  }
`;

export const INTEGRATIONS_SHOW_FRAGMENT = gql`
  fragment IntegrationsShowFragment on Integration {
    ...BasicIntegrationFragment
    active
    createdAt
    installedAt
    authorizedAt
    authorizedBy {
      apiTokenCount
      deactivated
      deactivatedAt
      email
      hasProvider
      href
      id
      name
      pending
      provisionedBy
      role
    }
    invalidatedAt
    displayName
    accountName
    accountUrl
    version
    stillLoading
    webhookUrl
    uninstallInProgress
    stale
    lastEventReceived
    earlyAccess
    ... on IncidentIoIntegration {
      availableCustomFields {
        externalId
        name
        catalogTypeName
        eligible
      }
    }
  }
  ${BASIC_INTEGRATION_FRAGMENT}
`;

export const REPO_INTEGRATION_FRAGMENT = gql`
  fragment RepoIntegrationFragment on Integration {
    id
    href
    type
    accountKey
    accountName
    accountUrl
    active
    appIdentifier
    authType
    authorizedAt
    baseUrl
    configUrl
    ignoredPatterns
    installedAt
    invalidatedAt
    invalidatedReason
    name
    notificationChannel
    serviceDiscoveryEnabled
    setWebhooksOnMonitors
    shortName
    stale
    stillLoading
    uninstallInProgress
    sbomGenerationEnabled

    authorizedBy {
      apiTokenCount
      deactivated
      deactivatedAt
      email
      hasProvider
      href
      id
      name
      pending
      provisionedBy
      role
    }

    ... on GithubIntegration {
      capabilities
      tokenExpiresOn
      personalRepoPatIntegration
      lastManualSync
      supportsTeamSync
      teamSyncEnabled
      canManuallySyncTeams
      lastManualTeamSync
    }
    ... on BitbucketIntegration {
      capabilities
      lastManualSync
    }
    ... on GitlabIntegration {
      capabilities
      tokenExpiresOn
      remoteReposWithoutProperAccessLevel
      lastManualSync
    }
    ... on OnPremGitlabIntegration {
      lastManualSync
      capabilities
      tokenExpiresOn
      remoteReposWithoutProperAccessLevel
    }
    ... on AzureDevopsIntegration {
      lastManualSync
      capabilities
      permissionsErrors {
        name
        type
        permissions
      }
      accountErrors
    }
  }
`;

export const GIT_INTEGRATION_FRAGMENT = gql`
  fragment GitIntegrationFragment on Integration {
    id
    name
    type
    installedAt
    valid
  }
`;

export const INTEGRATION_WITH_CHECK_CREATE_TEMPLATES_CONFIG_FRAGMENT = gql`
  fragment IntegrationWithCheckCreateTemplateConfigFragment on Integration {
    ...BasicIntegrationFragment
    gid: id
    webhookUrl
    htmlUrl
    valid
    active
    config {
      checkCreateTemplates {
        defaultName
        resultMessage
        samplePayload
        sampleQueryParams
        serviceSpecifier
        successCondition
        passPending
      }
    }
  }
  ${BASIC_INTEGRATION_FRAGMENT}
`;

export const INTEGRATION_NAME_MUTATION = gql`
  mutation integrationUpdate($id: ID!, $name: String) {
    integrationUpdate(input: { id: $id, name: $name }) {
      errors {
        message
        path
      }
      integration {
        ...BasicIntegrationFragment
      }
    }
  }
  ${BASIC_INTEGRATION_FRAGMENT}
`;

export const INTEGRATION_SYNC_ALERT_SOURCES_MUTATION = gql`
  mutation integrationSyncAlertSources($id: ID!) {
    integrationSyncAlertSources(input: { id: $id }) {
      errors {
        message
        path
      }
      integration {
        ...BasicIntegrationFragment
      }
    }
  }
  ${BASIC_INTEGRATION_FRAGMENT}
`;

export const INTEGRATION_SYNC_MUTATION = gql`
  mutation integrationSync($id: ID!, $type: IntegrationSyncTypeEnum) {
    integrationSync(input: { integration: { id: $id }, type: $type }) {
      errors {
        message
        path
      }
      integration {
        ...RepoIntegrationFragment
      }
    }
  }
  ${REPO_INTEGRATION_FRAGMENT}
`;

export const INTEGRATION_DEAUTH_MUTATION = gql`
  mutation integrationDeauth($id: ID!, $force: Boolean) {
    integrationDeauth(input: { id: $id, force: $force }) {
      errors {
        message
        path
      }
      integration {
        ...BasicIntegrationFragment
      }
    }
  }
  ${BASIC_INTEGRATION_FRAGMENT}
`;

export const SET_INTEGRATION_GROUP = gql`
  mutation integrationUpdate($id: ID!, $group_id: String!) {
    integrationGroupAssign(input: { id: $id, groupId: $group_id }) {
      errors {
        message
        path
      }
      integration {
        ...BasicIntegrationFragment
      }
    }
  }
  ${BASIC_INTEGRATION_FRAGMENT}
`;

export const QUERY_INTEGRATIONS = gql`
  query queryIntegrations(
    $type: String
    $searchTerm: String
    $invalidated: Boolean
  ) {
    account {
      integrations(
        type: $type
        searchTerm: $searchTerm
        invalidated: $invalidated
      ) {
        nodes {
          ...IntegrationWithCheckCreateTemplateConfigFragment
        }
      }
    }
  }
  ${INTEGRATION_WITH_CHECK_CREATE_TEMPLATES_CONFIG_FRAGMENT}
`;

export const QUERY_INTEGRATIONS_INDEX = gql`
  query queryIntegrationsIndex(
    $searchTerm: String
    $sortBy: IntegrationSortEnum
    $invalidated: Boolean
  ) {
    account {
      integrations(
        searchTerm: $searchTerm
        sortBy: $sortBy
        invalidated: $invalidated
      ) {
        nodes {
          ...IntegrationsIndexFragment
          ... on GitlabIntegration {
            capabilities
          }
          ... on GithubIntegration {
            capabilities
          }
          ... on OnPremGitlabIntegration {
            capabilities
          }
          ... on BitbucketIntegration {
            capabilities
          }
          ... on AzureDevopsIntegration {
            capabilities
          }
        }
      }
    }
  }
  ${INTEGRATIONS_INDEX_FRAGMENT}
`;

export const UPDATE_INTEGRATION_CREDENTIALS = gql`
  mutation (
    $integrationId: ID!
    $name: IntegrationCredentialEnum!
    $token: String!
  ) {
    integrationCredentialUpdate(
      input: { id: $integrationId, name: $name, token: $token }
    ) {
      success
      errors {
        message
        path
      }
    }
  }
`;

export const UPDATE_DATADOG_CREDENTIALS = gql`
  mutation ($integrationId: ID!, $appKey: String!, $apiKey: String!) {
    datadogCredentialsUpdate(
      input: { integrationId: $integrationId, appKey: $appKey, apiKey: $apiKey }
    ) {
      success
      errors {
        message
        path
      }
    }
  }
`;

export const UPDATE_DD_NOTIFICATION_CHANNEL = gql`
  mutation ($integrationId: ID!, $notificationChannel: String) {
    integrationUpdate(
      input: { id: $integrationId, notificationChannel: $notificationChannel }
    ) {
      errors {
        message
        path
      }
      integration {
        ...BasicIntegrationFragment
      }
    }
  }
  ${BASIC_INTEGRATION_FRAGMENT}
`;

export const UPDATE_DD_SET_WEBHOOKS_ON_MONITORS = gql`
  mutation ($integrationId: ID!, $setWebhooksOnMonitors: Boolean) {
    integrationUpdate(
      input: {
        id: $integrationId
        setWebhooksOnMonitors: $setWebhooksOnMonitors
      }
    ) {
      errors {
        message
        path
      }
      integration {
        ...BasicIntegrationFragment
      }
    }
  }
  ${BASIC_INTEGRATION_FRAGMENT}
`;

export const UPDATE_INTEGRATION_BASE_URL = gql`
  mutation integrationUpdate($id: ID!, $baseUrl: String) {
    integrationUpdate(input: { id: $id, baseUrl: $baseUrl }) {
      errors {
        message
        path
      }
      integration {
        ...BasicIntegrationFragment
      }
    }
  }
  ${BASIC_INTEGRATION_FRAGMENT}
`;

export const UPDATE_INTEGRATION_SERVICE_DISCOVERY_ENABLED = gql`
  mutation integrationServiceDetectionToggle(
    $id: ID!
    $serviceDiscoveryEnabled: Boolean
  ) {
    integrationUpdate(
      input: { id: $id, serviceDiscoveryEnabled: $serviceDiscoveryEnabled }
    ) {
      errors {
        message
        path
      }
      integration {
        ...RepoIntegrationFragment
      }
    }
  }
  ${REPO_INTEGRATION_FRAGMENT}
`;

export const UPDATE_INTEGRATION_SBOM_GENERATION_ENABLED = gql`
  mutation integrationSBOMGenerationToggle(
    $id: ID!
    $sbomGenerationEnabled: Boolean
  ) {
    integrationUpdate(
      input: { id: $id, sbomGenerationEnabled: $sbomGenerationEnabled }
    ) {
      errors {
        message
        path
      }
      integration {
        ...RepoIntegrationFragment
      }
    }
  }
  ${REPO_INTEGRATION_FRAGMENT}
`;

export const UPDATE_INTEGRATION_SERVICE_DISCOVERY_IGNORE_RULES = gql`
  mutation integrationUpdateIgnoreRules($id: ID!, $ignoredPatterns: [String!]) {
    integrationUpdate(input: { id: $id, ignoredPatterns: $ignoredPatterns }) {
      errors {
        message
        path
      }
      integration {
        ...RepoIntegrationFragment
      }
    }
  }
  ${REPO_INTEGRATION_FRAGMENT}
`;

export const UPDATE_INTEGRATION_ALLOWED_REPO_VISIBILITY = gql`
  mutation integrationUpdateAllowedRepoVis(
    $id: ID!
    $allowedRepoVis: [RepoVisibilityEnum!]
  ) {
    integrationUpdate(
      input: { id: $id, allowedRepoVisibility: $allowedRepoVis }
    ) {
      errors {
        message
        path
      }
      integration {
        ...BasicIntegrationFragment
        ... on GithubIntegration {
          allowedRepoVisibility
        }
      }
    }
  }
  ${BASIC_INTEGRATION_FRAGMENT}
`;

export const UPDATE_INTEGRATION_TEAM_SYNC = gql`
  mutation ($integrationId: ID!, $teamSyncEnabled: Boolean!) {
    integrationUpdate(
      input: { id: $integrationId, teamSyncEnabled: $teamSyncEnabled }
    ) {
      errors {
        message
        path
      }
      integration {
        ...RepoIntegrationFragment
      }
    }
  }
  ${REPO_INTEGRATION_FRAGMENT}
`;

export const UPDATE_INTEGRATION_WARNING = gql`
  mutation ($warningId: ID!, $dismissed: Boolean!) {
    integrationWarningUpdate(input: { id: $warningId, dismissed: $dismissed }) {
      errors {
        message
        path
      }
      integration {
        ...BasicIntegrationFragment
      }
    }
  }
  ${BASIC_INTEGRATION_FRAGMENT}
`;

export const FETCH_GIT_FORGE_INTEGRATIONS = gql`
  query gitIntegrations {
    account {
      github: integrations(type: "github") {
        nodes {
          ...GitIntegrationFragment
          ... on GithubIntegration {
            capabilities
          }
        }
      }
      gitlab: integrations(type: "gitlab") {
        nodes {
          ...GitIntegrationFragment
          ... on GitlabIntegration {
            capabilities
          }
        }
      }
      gitlabSelfManaged: integrations(type: "gitlabSelfManaged") {
        nodes {
          ...GitIntegrationFragment
          ... on OnPremGitlabIntegration {
            capabilities
          }
        }
      }
      azureDevops: integrations(type: "azure_devops") {
        nodes {
          ...GitIntegrationFragment
          ... on AzureDevopsIntegration {
            capabilities
          }
        }
      }
    }
  }
  ${GIT_INTEGRATION_FRAGMENT}
`;

const AVAILABLE_INTEGRATIONS_QUERY_FRAGMENT = gql`
  fragment AvailableIntegrationsQueryFragment on Query {
    integrationCategories: __type(name: "IntegrationCategoryEnum") {
      enumValues {
        name
        description
      }
    }
    account {
      availableIntegrations(categories: $categories, searchTerm: $searchTerm) {
        displayName
        installUrl
        earlyAccess
        categories {
          key
          displayName
        }
      }
    }
  }
`;

export const FETCH_AVAILABLE_INTEGRATIONS = gql`
  query fetchAvailableIntegrations(
    $categories: [IntegrationCategoryEnum!]
    $searchTerm: String
  ) {
    ...AvailableIntegrationsQueryFragment
  }
  ${AVAILABLE_INTEGRATIONS_QUERY_FRAGMENT}
`;

export const FETCH_AVAILABLE_INTEGRATIONS_INITIAL_LOAD = gql`
  query fetchAvailableIntegrationsInitialLoad(
    $categories: [IntegrationCategoryEnum!]
    $searchTerm: String
  ) {
    ...AvailableIntegrationsQueryFragment
  }
  ${AVAILABLE_INTEGRATIONS_QUERY_FRAGMENT}
`;

export const DELETE_GITFORGE_INTEGRATION_MUTATION = gql`
  mutation gitForgeIntegrationDelete($id: ID!) {
    gitForgeIntegrationDelete(input: { id: $id }) {
      errors {
        message
        path
        title
      }
    }
  }
`;

export const DELETE_INTEGRATION_MUTATION = gql`
  mutation integrationDelete($identifierInput: IdentifierInput!) {
    integrationDelete(resource: $identifierInput) {
      errors {
        message
        path
        title
      }
    }
  }
`;

export const VALIDATE_INTEGRATION_CREDENTIALS = gql`
  mutation integrationValidateCredentials(
    $type: IntegrationValidateCredentialsTypeEnum!
    $options: JSON!
  ) {
    integrationValidateCredentials(type: $type, options: $options) {
      valid
      tokenOwnerChange {
        from
        to
      }
      organizations {
        avatarUrl
        description
        eventsUrl
        hooksUrl
        id
        issuesUrl
        login
        membersUrl
        nodeId
        publicMembersUrl
        reposUrl
        url
        integrated
      }
      user {
        login
        avatarUrl
        integrated
      }
      errors {
        message
        path
        title
      }
    }
  }
`;
export const FETCH_GENERIC_INTEGRATIONS = gql`
  query genericIntegrations {
    account {
      availableIntegrations(categories: [custom]) {
        displayName
        installUrl
        categories {
          displayName
          key
        }
      }
    }
  }
`;

export const FETCH_INTREGRATION_SHOW_DETAILS = gql`
  query integration($id: ID!) {
    account {
      integration(id: $id) {
        ...IntegrationsShowFragment
        displayName
        active
        authorizedBy {
          apiTokenCount
          deactivated
          deactivatedAt
          email
          hasProvider
          href
          id
          name
          pending
          provisionedBy
          role
        }
      }
    }
  }
  ${INTEGRATIONS_SHOW_FRAGMENT}
`;

export const FETCH_INFRA_INTEGRATION_DETAILS = gql`
  query infraIntegration($id: ID!) {
    account {
      integration(id: $id) {
        ...BasicIntegrationFragment
        createdAt
        displayName
        authorizedBy {
          id
          name
          deactivatedAt
          href
        }

        active
        stale
        stillLoading
        invalidatedAt
        invalidatedReason
        uninstallInProgress

        warnings {
          id
          message
          createdAt
          dismissed
        }

        ... on AwsIntegration {
          ownershipTagKeys
          lastSyncedAt
          awsTagsOverrideOwnership
          tagRelationshipKeys {
            belongsTo
            dependsOn
            dependencyOf
          }
          allowManualSyncInfrastructureResources
          minutesUntilManualSyncInfrastructureResourcesAllowed
        }
        ... on AzureResourcesIntegration {
          tenantId
          subscriptionId
          lastSyncedAt
          ownershipTagKeys
          tagsOverrideOwnership
          tagRelationshipKeys {
            belongsTo
            dependsOn
            dependencyOf
          }
          allowManualSyncInfrastructureResources
          minutesUntilManualSyncInfrastructureResourcesAllowed
        }
        ... on GoogleCloudIntegration {
          lastSyncedAt
          ownershipTagKeys
          tagsOverrideOwnership
          tagRelationshipKeys {
            belongsTo
            dependsOn
            dependencyOf
          }
          allowManualSyncInfrastructureResources
          minutesUntilManualSyncInfrastructureResourcesAllowed
          projects {
            id
            name
            url
          }
        }
      }
    }
  }
  ${BASIC_INTEGRATION_FRAGMENT}
`;

export const FETCH_INTEGRATION_DETAILS = gql`
  query integrationDetails($id: ID!) {
    account {
      integration(id: $id) {
        ...RepoIntegrationFragment
      }
    }
  }
  ${REPO_INTEGRATION_FRAGMENT}
`;

export const CREATE_NEW_RELIC_INTEGRATION = gql`
  mutation createNewRelicIntegration(
    $apiKey: String!
    $baseUrl: String!
    $accountKey: String!
  ) {
    newRelicIntegrationCreate(
      input: { apiKey: $apiKey, baseUrl: $baseUrl, accountKey: $accountKey }
    ) {
      integration {
        ...BasicIntegrationFragment
        href
      }
      errors {
        message
        path
        title
      }
    }
  }
  ${BASIC_INTEGRATION_FRAGMENT}
`;

export const UPDATE_NEW_RELIC_INTEGRATION = gql`
  mutation updateNewRelicIntegration(
    $integration: IdentifierInput!
    $apiKey: String
    $baseUrl: String
    $accountKey: String
  ) {
    newRelicIntegrationUpdate(
      input: { apiKey: $apiKey, baseUrl: $baseUrl, accountKey: $accountKey }
      resource: $integration
    ) {
      integration {
        ...BasicIntegrationFragment
        href
      }
      errors {
        message
        path
        title
      }
    }
  }
  ${BASIC_INTEGRATION_FRAGMENT}
`;

export const FETCH_NEW_RELIC_ACCOUNTS = gql`
  mutation fetchNewRelicAccounts($apiKey: String!, $baseUrl: String!) {
    newRelicAccounts(input: { apiKey: $apiKey, baseUrl: $baseUrl }) {
      accounts {
        newRelicAccountId
        newRelicAccountName
        integration {
          id
        }
      }
      errors {
        message
        path
        title
      }
    }
  }
`;

export const CREATE_SAMPLE_GIT_INTEGRATION = gql`
  mutation createSampleGitIntegration {
    sampleIntegrationCreate {
      integration {
        id
        name
        href
      }
      errors {
        path
        title
        message
      }
    }
  }
`;

export const SUBSCRIBE_INTEGRATION_DETAILS = gql`
  subscription subscribeToIntegrationStatus($integrationId: ID!) {
    integrationStatusUpdated(integrationId: $integrationId) {
      ...RepoIntegrationFragment
    }
  }
  ${REPO_INTEGRATION_FRAGMENT}
`;

export const CREATE_AWS_INTEGRATION = gql`
  mutation createAwsIntegration(
    $name: String
    $iamRole: String!
    $externalId: String!
  ) {
    awsIntegrationCreate(
      input: { name: $name, iamRole: $iamRole, externalId: $externalId }
    ) {
      integration {
        ...BasicIntegrationFragment
      }
      errors {
        message
        path
        title
      }
    }
  }
  ${BASIC_INTEGRATION_FRAGMENT}
`;

export const CREATE_AZURE_RESOURCES_INTEGRATION = gql`
  mutation createAzureResourcesIntegration(
    $name: String!
    $tenantId: String!
    $subscriptionId: String!
    $clientId: String!
    $clientSecret: String!
  ) {
    azureResourcesIntegrationCreate(
      input: {
        name: $name
        tenantId: $tenantId
        subscriptionId: $subscriptionId
        clientId: $clientId
        clientSecret: $clientSecret
      }
    ) {
      integration {
        ...BasicIntegrationFragment
      }
      errors {
        message
        path
        title
      }
    }
  }
  ${BASIC_INTEGRATION_FRAGMENT}
`;

export const CREATE_GOOGLE_CLOUD_INTEGRATION = gql`
  mutation createGoogleCloudIntegration(
    $name: String!
    $clientEmail: String!
    $privateKey: String!
  ) {
    googleCloudIntegrationCreate(
      input: { name: $name, clientEmail: $clientEmail, privateKey: $privateKey }
    ) {
      integration {
        ...BasicIntegrationFragment
      }
      errors {
        message
        path
        title
      }
    }
  }
  ${BASIC_INTEGRATION_FRAGMENT}
`;

export const CREATE_SNYK_INTEGRATION = gql`
  mutation (
    $region: SnykIntegrationRegionEnum
    $apiKey: String
    $groupId: String
  ) {
    snykIntegrationCreate(
      input: {
        name: "Snyk Enterprise"
        region: $region
        apiKey: $apiKey
        groupId: $groupId
      }
    ) {
      integration {
        name
        href
        ... on SnykIntegration {
          groupId
        }
      }
      errors {
        message
      }
    }
  }
`;

export const CREATE_INCIDENT_IO_INTEGRATION = gql`
  mutation ($apiKey: String) {
    incidentIoIntegrationCreate(input: { apiKey: $apiKey }) {
      integration {
        name
        href
      }
      errors {
        message
      }
    }
  }
`;

export const UPDATE_INCIDENT_IO_INTEGRATION = gql`
  mutation ($integration: IdentifierInput!, $mappedCustomFieldId: String) {
    incidentIoIntegrationUpdate(
      integration: $integration
      input: { mappedCustomFieldId: $mappedCustomFieldId }
    ) {
      integration {
        ...IntegrationsShowFragment
      }
      errors {
        message
      }
    }
  }
  ${INTEGRATIONS_SHOW_FRAGMENT}
`;

export const CREATE_SONARQUBE_INTEGRATION = gql`
  mutation ($apiKey: String, $baseUrl: String) {
    sonarqubeIntegrationCreate(
      input: { name: "SonarQube", apiKey: $apiKey, baseUrl: $baseUrl }
    ) {
      integration {
        name
        href
      }
      errors {
        message
      }
    }
  }
`;

export const CREATE_SONARQUBE_CLOUD_INTEGRATION = gql`
  mutation ($apiKey: String, $organizationKey: String) {
    sonarqubeCloudIntegrationCreate(
      input: {
        name: "SonarQube Cloud"
        apiKey: $apiKey
        organizationKey: $organizationKey
      }
    ) {
      integration {
        name
        href
      }
      errors {
        message
      }
    }
  }
`;

export const CREATE_OCTOPUS_DEPLOY_INTEGRATION = gql`
  mutation ($apiKey: String, $instanceUrl: String) {
    octopusDeployIntegrationCreate(
      input: {
        name: "Octopus Deploy"
        apiKey: $apiKey
        instanceUrl: $instanceUrl
      }
    ) {
      integration {
        id
        name
        href
      }
      errors {
        message
      }
    }
  }
`;

export const UPDATE_OCTOPUS_DEPLOY_INTEGRATION = gql`
  mutation octopusDeployIntegrationUpdate(
    $apiKey: String
    $instanceUrl: String
    $integration: IdentifierInput!
  ) {
    octopusDeployIntegrationUpdate(
      input: {
        name: "Octopus Deploy"
        apiKey: $apiKey
        instanceUrl: $instanceUrl
      }
      integration: $integration
    ) {
      integration {
        ...BasicIntegrationFragment
      }
      errors {
        message
      }
    }
  }
  ${BASIC_INTEGRATION_FRAGMENT}
`;
